import Modal from './../../components/shared/modal';
import type { FC } from 'react';
import ModalWrapper from './../../components/shared/modal/modal-wrapper';
import { submitContactRequest } from '../../graphql/mutation';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { IoMdClose } from '@react-icons/all-files/io/IoMdClose';
import { toast } from 'react-toastify';
import { navigate } from 'gatsby';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

const RequestForm: FC<{ show: boolean; setShow: any }> = ({
  show,
  setShow,
}) => {
  // const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // const phoneNumberPattern = /^(\+91\s?)?[789]\d{9,14}$/;

  async function handleSubmit(): Promise<void> {
    try {
      if (!name.trim()) {
        toast.error('Name cannot be empty');
        return;
      }

      if (!email.trim()) {
        toast.error('Email cannot be empty');
        return;
      }

      if (!emailPattern.test(email)) {
        toast.error('Invalid email address');
        return;
      }

      if (!phone.trim()) {
        toast.error('Phone number cannot be empty');
        return;
      }
      // if (!phoneNumberPattern.test(phone)) {
      //   toast.error('Invalid phone number');
      //   return;
      // }
      if (!message.trim()) {
        toast.error('Message cannot be empty');
        return;
      }

      const postData = {
        operationName: 'ContactRequestForm',
        variables: {
          data: {
            Name: name,
            Email: email,
            Phone: phone,
            Message: message,
          },
        },
        query: submitContactRequest,
      };
      setLoading(true);

      const response = await fetch(`${process.env.GATSBY_API_URL}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.GATSBY_API_SECRET}`,
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        setLoading(false);
        toast.error('Something went wrong');
        return;
      }

      await response.json();
      setLoading(false);
      toast.success('Contact request submitted successfully');
      setShow(false);
      await navigate(`/thank-you?confirm=${response.ok}`);
    } catch (error) {
      toast.error('Something went wrong');
      setLoading(false);
    }
  }

  return (
    <div className="fixed left-0 items-center bg-action-btn2 px-2 py-1.5 rounded-r-lg rquestBotton">
      <button
        className="text-sm uppercase text-white font-bold"
        onClick={() => {
          setShow(true);
        }}
      >
        Request information
      </button>

      <Modal setShowModal={setShow} showModal={show}>
        <ModalWrapper>
          <motion.div
            animate={{ y: 0 }}
            className="relative rounded-md shadow-2xl  
          m-auto bg-gray-50 md:p-10 p-6 max-w-3xl w-full border reqModal"
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            <div className="flex justify-end">
              <button
                className="transition-smooth hover:scale-105"
                onClick={() => {
                  setShow(false);
                }}
                type="button"
              >
                <IoMdClose className="text-2xl " />
              </button>
            </div>
            <form
              className="bg-white border p-8 w-full lg:ml-auto flex flex-col gap-10"
              onSubmit={(ev) => {
                ev.preventDefault();
                void handleSubmit();
              }}
            >
              <input
                className="placeholder:font-light placeholder:text-sm border-b focus:outline-none sm:w-96 w-full px-1.5 py-2"
                onChange={(ev) => {
                  setName(ev.target.value);
                }}
                placeholder="YOUR NAME"
                value={name}
              />

              <input
                className="placeholder:font-light placeholder:text-sm border-b focus:outline-none sm:w-96 w-full px-1.5 py-2"
                onChange={(ev) => {
                  setEmail(ev.target.value);
                }}
                placeholder="EMAIL ADDRESS"
                value={email}
              />

              {/* <input
                className="placeholder:font-light placeholder:text-sm border-b focus:outline-none sm:w-96 w-full px-1.5 py-2"
                onChange={(ev) => {
                  setPhone(ev.target.value);
                }}
                placeholder="PHONE NUMBER"
                value={phone}
              /> */}

              <PhoneInput
                className="placeholder:font-light placeholder:text-sm border-b focus:outline-none sm:w-96 w-full px-1.5 py-2 w-full"
                defaultCountry="in"
                value={phone}
                onChange={(phone) => {
                  console.log(phone)
                  setPhone(phone)
                }}
              />

              <textarea
                className="placeholder:font-light placeholder:text-sm border-b focus:outline-none sm:w-96 w-full px-1.5 py-2"
                onChange={(ev) => {
                  setMessage(ev.target.value);
                }}
                placeholder="MESSAGE"
                value={message}
              />

              <button
                className="bg-black text-gray-50 text-center py-2.5 mt-6 transition-smooth hover:-translate-y-1"
                type="submit"
              >
                <span>{loading ? 'PLEASE WAIT' : 'SUBMIT'}</span>
              </button>
            </form>
          </motion.div>
        </ModalWrapper>
      </Modal>
    </div>
  );
};

export default RequestForm;
