import { IoCallOutline } from '@react-icons/all-files/io5/IoCallOutline';
import type { ReactNode } from 'react';

export default function HeaderCallButton(): ReactNode {
  return (
    <a
      className="flex items-center space-x-2 justify-end text-gray-50 bg-action-btn p-2 rounded-lg
      transition-smooth hover:-translate-y-1"
      href="tel:+91 8427641925"
    >
      <IoCallOutline className="text-xl" />
      <p className="whitespace-nowrap font-semibold">+91 8427641925</p>
    </a>
  );
}
