import type { NavLink } from '../@types/app';

export const navLinks: NavLink[] = [
  {
    href: '/industries',
    name: 'INDUSTRIES',
    submenu: [
      {
        href: '/industries/services',
        name: 'Services',
      },
      {
        href: '/industries/workpiece-by-industry',
        name: 'Workpiece by Industry',
      },
      {
        href: '/accuracy-cube',
        name: 'Accuracy Cube',
      },
      {
        href: '/accessories',
        name: 'Accessories',
      },
    ],
  },
  {
    href: '/resources',
    name: 'RESOURCES',
    submenu: [
      { href: '/events', name: 'Events' },
      { href: '/blog', name: 'Blogs' },
      { href: '/case-studies', name: 'Case Studies' },
      { href: '/guides', name: 'Guides' },
      { href: '/whitepaper', name: 'Whitepaper' },
      { href: '/technical-press-news', name: 'Technical Press News' },
      { href: '/faqs', name: 'FAQs' },
    ],
  },
  {
    href: '/about',
    name: 'ABOUT US',
    submenu: [
      {
        href: '/about',
        name: 'Our Vision',
      },
      {
        href: '/about/social-responsibility',
        name: 'Corporate social responsibility',
      },
      {
        href: '/about/managements',
        name: 'Managements',
      },
      {
        href: '/about/message-from-cmd',
        name: 'Message From CMD',
      },

      {
        href: '/about/quality-standards-and-infrastructure',
        name: 'Quality Standards & Infrastructure',
      },
    ],
  },
  { href: '/sales-and-services', name: 'SALES & SERVICES' },
  { href: '/contact-us', name: 'CONTACT US' },
];
