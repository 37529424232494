import { Link } from 'gatsby';
import { type FC } from 'react';

import { navLinks } from '../../../data/nav';
import ProductsNav from './product-nav';
import Submenu from './sub-menu';

const LayoutNav: FC = () => {
  return (
    <nav className="max-w-6xl h-20 flex items-end">
      <ul className="lg:flex hidden text-sm space-x-4 items-center w-full">
        <ProductsNav />
        {navLinks.map((link) => {
          if (!link.submenu) {
            return (
              <li
                className="font-semibold flex items-center px-2 transition-smooth
              hover:decoration-[#EE2F2A] hover:underline cursor-pointer underline-offset-[12.5px] decoration-2"
                key={link.name}
              >
                <Link
                  className="flex space-x-1 items-center whitespace-nowrap pb-2"
                  to={link.href}
                >
                  <span>{link.name}</span>
                </Link>
              </li>
            );
          }

          return <Submenu key={link.name} {...link} />;
        })}
      </ul>
    </nav>
  );
};

export default LayoutNav;
